<template>
  <div class="app-container">
    <UserVote></UserVote>
  </div>
</template>

<script>
import UserVote from './components/page/userVote.vue'
export default {
  components:{
    UserVote
  }
}
</script>

<style>

</style>