<template>
  <div>
    <div class="uservote-container" v-if="isUser != ''">
      <div class="header_cs">
        <van-row type="flex" justify="space-between">
          <van-col
            span="12"
            style="white-space: nowrap; font-size: 15px; padding-left: 10px"
          >
            <div class="van-ellipsis">
              {{ titleData.title }}
            </div>
          </van-col>
          <van-col
            span="12"
            style="
              white-space: nowrap;
              font-size: 12px;
              text-align: right;
              padding-right: 10px;
            "
            >截止时间:
            <span style="white-space: nowrap">{{
              titleData.voteEndTime
            }}</span></van-col
          >
        </van-row>
      </div>
      <div style="padding-top: 60px"></div>
      <div class="voteList_cs">
        <div class="one">
          <ul>
            <li
              v-for="(item, index) in voteListLeft"
              :key="index"
              class="vote_list"
            >
              <van-image
                v-if="item.productImg != '' || item.productImg != undefined"
                :src="item.productImg[0]"
                @click="showImg(item.productImg)"
              />
              <van-image
                v-else
                :src="require('../../assets/img/nullImg.webp')"
                @click="showImg(item.productImg)"
              />
              
              <div class="text_cs">
                <!-- <div
                  class="van-ellipsis title_cs"
                  v-if="!(openIdx == item.meetingProductId)"
                  v-html="html_decode(item.productName)"
                ></div> -->
                <div
                  class="title_cs"
                  v-html="html_decode(item.productName)"
                ></div>
                <!-- <div
                  class="van-multi-ellipsis--l2 contents_cs"
                  v-if="!(openIdx == item.meetingProductId)"
                  v-html="html_decode(item.description)"
                ></div> -->
                <div class="contents_cs">
                  <span v-html="html_decode(item.description)"></span>
                  <span
                    style="color: blue; background-color: white"
                    @click="getCut(item.meetingProductId)"
                  >
                    <!-- <van-image
                      width="15"
                      height="15"
                      :src="require('../../assets/img/top.png')"
                    /> -->
                  </span>
                </div>
                <span
                  style="
                    color: blue;
                    display: inline-block;
                    padding-left: 7px;
                    position: relative;
                    z-index: 2;
                    top: -20px;
                    float: right;
                    background-color: white;
                  "
                  v-if="leftOpen[index]"
                >
                  <!-- <span
                    @click="getOpen(item.meetingProductId)"
                    v-show="!(openIdx == item.meetingProductId)"
                    ><span style="position: relative; top: -4px">...</span
                    ><van-image
                      width="15"
                      height="15"
                      :src="require('../../assets/img/bottom.png')"
                  /></span> -->
                </span>

                <div class="funcs_cs">
                  <p style="text-align: left">
                    <!-- <span>拟售价：</span> -->
                    <span class="price_cs">¥</span>
                    <span class="family_cs" style="color: #ff4900">
                      {{ item.price|currency("") }}</span
                    >
                    <span class="price_cs">{{item.price|currency1}}</span>
                    <span class="oriPrice_cs">{{item.oriPrice|currency2('¥')}}</span>
                  </p>
                  <p style="text-align: right">
                    <span v-if="disabledBtn">
                      <span
                        class="funcBtn"
                        @click="toupinao(item.meetingProductId)"
                        v-if="!item.voted"
                        >投票</span
                      >
                      <span class="funcBtn2" v-else>已投票</span>
                    </span>
                    <span v-else>
                      <span class="funcBtn1" v-if="!item.voted">投票</span>
                      <span class="funcBtn2" v-else>已投票</span>
                    </span>
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="two">
          <ul>
            <li
              v-for="(item, index) in voteListRight"
              :key="index"
              class="vote_list"
            >
              <van-image
                v-if="item.productImg != '' || item.productImg != undefined"
                :src="item.productImg[0]"
                @click="showImg(item.productImg)"
              />
              <van-image
                v-else
                :src="require('../../assets/img/nullImg.webp')"
                @click="showImg(item.productImg)"
              />
              <div class="text_cs">
                <!-- <div
                  v-if="!(openIdx == item.meetingProductId)"
                  class="van-ellipsis title_cs"
                  v-html="html_decode(item.productName)"
                ></div> -->
                <div
                  class="title_cs"
                  v-html="html_decode(item.productName)"
                ></div>
                <!-- <div
                  class="van-multi-ellipsis--l2 contents_cs"
                  v-if="!(openIdx == item.meetingProductId)"
                  v-html="html_decode(item.description)"
                ></div> -->
                <div class="contents_cs">
                  <span v-html="html_decode(item.description)"></span>
                  <span
                    style="color: blue; background-color: white"
                    @click="getCut(item.meetingProductId)"
                    >
                    <!-- <van-image
                      width="15"
                      height="15"
                      :src="require('../../assets/img/top.png')"
                  /> -->
                  </span>
                </div>
                <span
                  style="
                    color: blue;
                    display: inline-block;
                    padding-left: 7px;
                    position: relative;
                    z-index: 2;
                    top: -20px;
                    float: right;
                    background-color: white;
                  "
                  v-if="rightOpen[index]"
                >
                  <!-- <span
                    @click="getOpen(item.meetingProductId)"
                    v-show="!(openIdx == item.meetingProductId)"
                    ><span style="position: relative; top: -4px">...</span
                    ><van-image
                      width="15"
                      height="15"
                      :src="require('../../assets/img/bottom.png')"
                  /></span> -->
                </span>
                <div class="funcs_cs">
                  <p style="text-align: left">
                    <!-- <span>拟售价：</span> -->
                    <span class="price_cs">¥</span>
                    <span class="family_cs" style="color: #ff4900">
                      {{ item.price|currency("") }} </span
                    >
                    <span class="price_cs last_cs">{{item.price|currency1}}</span>
                    <span class="oriPrice_cs">{{item.oriPrice|currency2('¥')}}</span>
                  </p>
                  <p style="text-align: right">
                    <span v-if="disabledBtn">
                      <span
                        class="funcBtn"
                        @click="toupinao(item.meetingProductId)"
                        v-if="!item.voted"
                        >投票</span
                      >
                      <span class="funcBtn2" v-else>已投票</span>
                    </span>
                    <span v-else>
                      <span class="funcBtn1" v-if="!item.voted">投票</span>
                      <span class="funcBtn2" v-else>已投票</span>
                    </span>
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <van-overlay :show="show">
        <div class="wrapper" @click.stop>
          <div
            style="
              z-index: 9;
              width: 150px;
              margin: 20vh auto 4vh;
              border-radius: 100px;
              overflow: hidden;
              background-color: #ffffcc;
              box-shadow: 0 0 10px #ccc;
            "
          >
            <img
              width="100%"
              :src="require('../../assets/img/cloneIMG.gif')"
              alt=""
            />
          </div>
          <div
            style="
              color: white;
              position: relative;
              z-index: 9;
              text-align: center;
            "
          >
            本轮投票已结束...
          </div>
        </div>
      </van-overlay>
    </div>


    <!-- 不是用户 -->
    <div class="uservote-container1" v-else>
      <div class="header_cs1">
        <van-row type="flex" justify="space-between">
          <van-col
            span="12"
            style="white-space: nowrap; font-size: 15px; padding-left: 10px"
          >
            <div class="van-ellipsis">
              {{ titleData.title }}
            </div>
          </van-col>
          <van-col
            span="12"
            style="
              white-space: nowrap;
              font-size: 12px;
              text-align: right;
              padding-right: 10px;
            "
            >截止时间:
            <span style="white-space: nowrap">{{
              titleData.voteEndTime
            }}</span></van-col
          >
        </van-row>
      </div>
      <div style="padding-top: 60px"></div>
      <div class="voteList_cs1">
        <div class="one">
          <ul>
            <li
              v-for="(item, index) in voteListLeft"
              :key="index"
              class="vote_list"
            >
              <van-image
                v-if="item.productImg != '' || item.productImg != undefined"
                :src="item.productImg[0]"
                @click="showImg(item.productImg)"
              />
              <van-image
                v-else
                :src="require('../../assets/img/nullImg.webp')"
                @click="showImg(item.productImg)"
              />
              
              <div class="text_cs">
                <!-- <div
                  class="van-ellipsis title_cs"
                  v-if="!(openIdx == item.meetingProductId)"
                  v-html="html_decode(item.productName)"
                ></div> -->
                <div
                  class="title_cs"
                  v-html="html_decode(item.productName)"
                ></div>
                <!-- <div
                  class="van-multi-ellipsis--l2 contents_cs"
                  v-if="!(openIdx == item.meetingProductId)"
                  v-html="html_decode(item.description)"
                ></div> -->
                <div class="contents_cs">
                  <span v-html="html_decode(item.description)"></span>
                  <span
                    style="color: blue; background-color: white"
                    @click="getCut(item.meetingProductId)"
                  >
                    <!-- <van-image
                      width="15"
                      height="15"
                      :src="require('../../assets/img/top.png')"
                    /> -->
                  </span>
                </div>
                <span
                  style="
                    color: blue;
                    display: inline-block;
                    padding-left: 7px;
                    position: relative;
                    z-index: 2;
                    top: -20px;
                    float: right;
                    background-color: white;
                  "
                  v-if="leftOpen[index]"
                >
                  <!-- <span
                    @click="getOpen(item.meetingProductId)"
                    v-show="!(openIdx == item.meetingProductId)"
                    ><span style="position: relative; top: -4px">...</span
                    ><van-image
                      width="15"
                      height="15"
                      :src="require('../../assets/img/bottom.png')"
                  /></span> -->
                </span>

                <div class="funcs_cs">
                  <p style="text-align: left">
                    <!-- <span>拟售价：</span> -->
                    <span class="price_cs">¥</span>
                    <span class="family_cs" style="color: #ff4900">
                      {{ item.price|currency("") }} </span
                    >
                    <span class="price_cs last_cs">{{item.price|currency1}}</span>
                    <span class="oriPrice_cs">{{item.oriPrice|currency2('¥')}}</span>
                  </p>
                  <p style="text-align: right">
                    <span v-if="disabledBtn">
                      <span
                        class="funcBtn"
                        @click="toupinao(item.meetingProductId)"
                        v-if="!item.voted"
                        >投票</span
                      >
                      <span class="funcBtn2" v-else>已投票</span>
                    </span>
                    <span v-else>
                      <span class="funcBtn1" v-if="!item.voted">投票</span>
                      <span class="funcBtn2" v-else>已投票</span>
                    </span>
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="two">
          <ul>
            <li
              v-for="(item, index) in voteListRight"
              :key="index"
              class="vote_list"
            >
              <van-image
                v-if="item.productImg != '' || item.productImg != undefined"
                :src="item.productImg[0]"
                @click="showImg(item.productImg)"
              />
              <van-image
                v-else
                :src="require('../../assets/img/nullImg.webp')"
                @click="showImg(item.productImg)"
              />
              <div class="text_cs">
                <!-- <div
                  v-if="!(openIdx == item.meetingProductId)"
                  class="van-ellipsis title_cs"
                  v-html="html_decode(item.productName)"
                ></div> -->
                <div
                  class="title_cs"
                  v-html="html_decode(item.productName)"
                ></div>
                <!-- <div
                  class="van-multi-ellipsis--l2 contents_cs"
                  v-if="!(openIdx == item.meetingProductId)"
                  v-html="html_decode(item.description)"
                ></div> -->
                <div class="contents_cs">
                  <span v-html="html_decode(item.description)"></span>
                  <span
                    style="color: blue; background-color: white"
                    @click="getCut(item.meetingProductId)"
                    >
                    <!-- <van-image
                      width="15"
                      height="15"
                      :src="require('../../assets/img/top.png')"
                  /> -->
                  </span>
                </div>
                <span
                  style="
                    color: blue;
                    display: inline-block;
                    padding-left: 7px;
                    position: relative;
                    z-index: 2;
                    top: -20px;
                    float: right;
                    background-color: white;
                  "
                  v-if="rightOpen[index]"
                >
                  <!-- <span
                    @click="getOpen(item.meetingProductId)"
                    v-show="!(openIdx == item.meetingProductId)"
                    ><span style="position: relative; top: -4px">...</span
                    ><van-image
                      width="15"
                      height="15"
                      :src="require('../../assets/img/bottom.png')"
                  /></span> -->
                </span>
                <div class="funcs_cs">
                  <p style="text-align: left">
                    <!-- <span>拟售价：</span> -->
                    <span class="price_cs">¥</span>
                    <span class="family_cs" style="color: #ff4900">
                      {{ item.price|currency("") }} </span
                    >
                    <span class="price_cs last_cs">{{item.price|currency1}}</span>
                    <span class="oriPrice_cs">{{item.oriPrice|currency2('¥')}}</span>
                  </p>
                  <p style="text-align: right">
                    <span v-if="disabledBtn">
                      <span
                        class="funcBtn"
                        @click="toupinao(item.meetingProductId)"
                        v-if="!item.voted"
                        >投票</span
                      >
                      <span class="funcBtn2" v-else>已投票</span>
                    </span>
                    <span v-else>
                      <span class="funcBtn1" v-if="!item.voted">投票</span>
                      <span class="funcBtn2" v-else>已投票</span>
                    </span>
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <van-overlay :show="show">
        <div class="wrapper" @click.stop>
          <div
            style="
              z-index: 9;
              width: 150px;
              margin: 20vh auto 4vh;
              border-radius: 100px;
              overflow: hidden;
              background-color: #ffffcc;
              box-shadow: 0 0 10px #ccc;
            "
          >
            <img
              width="100%"
              :src="require('../../assets/img/cloneIMG.gif')"
              alt=""
            />
          </div>
          <div
            style="
              color: white;
              position: relative;
              z-index: 9;
              text-align: center;
            "
          >
            本轮投票已结束...
          </div>
        </div>
      </van-overlay>
    </div>
  </div>

</template>

<script>
import { ImagePreview } from "vant";
import { currency, currency1, currency2 } from "./input-monry.js";
import { Toast } from "vant";
import { Notify } from "vant";
import { Dialog } from "vant";
import { getQueryVariable } from "../../../static/js/comm.js";
export default {
  data() {
    return {
      isUser: '',
      pointWidth: 0,
      show: false,
      textRed: false,
      leftOpen: [],
      rightOpen: [],
      disabledBtn: true,
      countIndex: 1,
      openIdx: -1,
      titleData: {
        title: "",
        voteEndTime: "",
        enable: 1,
      },
      voteList: [],
      voteListLeft: [],
      voteListRight: [],
    };
  },
  created() {
    this.pointWidth = document.body.clientWidth;
    this.isUser = getQueryVariable("isUser") || "";
    this.getVoteList();
    if (this.titleData.enable) {
      this.disabledBtn = true;
    } else {
      this.disabledBtn = false;
    }
  },
  filters:{
    currency: currency,
    currency1: currency1,
    currency2: currency2,
  },
  methods: {
    // 识别后台传递过来的转义符部分无法识别
    html_decode(str) {
      if (str == undefined || str == "") {
        return "  ";
      } else {
        var s = "";
        if (str.length == 0) return "";
        s = str.replace(/&amp;/g, "&");
        s = s.replace(/&lt;/g, "<");
        s = s.replace(/&gt;/g, ">");
        s = s.replace(/&nbsp;/g, " ");
        s = s.replace(/&#39;/g, "'");
        s = s.replace(/&amp;/g, "&");
        s = s.replace(/&quot;/g, '"');
        s = s.replace(/<br\/>/g, "\n");
        return s;
      }
    },
    getCut(index) {
      this.openIdx = -1;
    },
    getOpen(index) {
      this.openIdx = index;
    },
    showImg(imgUrl) {
      ImagePreview(imgUrl);
    },
    toupinao(meetingProductId) {
      Dialog.confirm({
        title: "投票确认",
        message: "您确定为该产品投票吗???",
      })
        .then(() => {
          this.$http
            .ajax(
              "/ffkj-main/meetingProductVote/vote",
              {
                meetingProductId: meetingProductId,
                openid: window.localStorage.openId,
                isUser: this.isUser,
              },
              "get",
              {}
            )
            .then((res) => {
              if (res.code == 0) {
                this.getVoteList();
                Toast.success("谢谢参与");
              } else {
                this.getVoteList();
                if (!(res.code == 20000)) {
                  Toast.fail({
                    message: res.message,
                    duration: 3500,
                  });
                }
              }
            });
        })
        .catch(() => {});
    },
    getVoteList() {
      let voteId = getQueryVariable("id") || "";
      this.$http
        .ajax(
          "/ffkj-main/meetingProductVote/getVote",
          {
            id: voteId,
            openid: window.localStorage.openId,
            isUser: this.isUser
          },
          "get",
          {}
        )
        .then((res) => {
          if (res.code == 0) {
            if (res.data.enable) {
              this.disabledBtn = true;
            } else {
              this.disabledBtn = false;
            }
            this.titleData.title = res.data.meetingName;
            this.titleData.voteEndTime = res.data.voteEndTime;
            this.voteList = res.data.products;
            this.voteListLeft = [];
            this.voteListRight = [];
            this.pointWidth = [(this.pointWidth - 20) / 2] / 12;
            let valueLength = parseInt(this.pointWidth);
            let titleLength = parseInt(titleLength / 2);
            for (let i = 0; i < res.data.products.length; i++) {
              var showOpen = true;
              if (
                res.data.products[i].productName != undefined &&
                res.data.products[i].description != undefined
              ) {
                if (
                  res.data.products[i].productName.length >= titleLength ||
                  res.data.products[i].description.length >= valueLength
                ) {
                  showOpen = true;
                } else {
                  showOpen = false;
                }
              } else {
                showOpen = false;
              }
              if ((i + 1) % 2 != 0) {
                this.leftOpen.push(showOpen);
                this.voteListLeft.push(res.data.products[i]);
                if (
                  res.data.products[i].productImg != "" ||
                  res.data.products[i].productImg != undefined
                ) {
                  let voteImg = res.data.products[i].productImg.split(",");
                  this.voteList[i].productImg = voteImg;
                }
              } else {
                this.rightOpen.push(showOpen);
                this.voteListRight.push(res.data.products[i]);
                if (
                  res.data.products[i].productImg != "" ||
                  res.data.products[i].productImg != undefined
                ) {
                  let voteImg = res.data.products[i].productImg.split(",");
                  this.voteList[i].productImg = voteImg;
                }
              }
            }
          } else {
            if (!(res.code == 20000)) {
              Toast.fail({
                message: res.message,
                duration: 3500,
              });
            }
          }
        });
    },
  },
};
</script>

<style></style>
