import Vue from 'vue'
import App from './App.vue'
import store from './store'
// import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';
import { getQueryVariable } from '../static/js/comm.js'
Vue.use(Vant);
import { Notify } from 'vant';
import './assets/css/index.css'
Vue.config.productionTip = false
import http from './api/http.js'
Vue.prototype.$http = http
import VueLazyload from "vue-lazyload";

Vue.use(VueLazyload);

//或者自定义配置插件
Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: require('./assets/img/errorImg.webp'),
    loading: require('./assets/img/loaddingImg.gif'),
    attempt: 1
})

import Vconsole from 'vconsole';
if (window.location.host.indexOf('lianhuiyouxuan') == -1) {
    new Vconsole();
    window.onload = function() {
        document.querySelector('.vc-switch').innerHTML = '联汇优选调试'
    }
}


comm();

function comm() {
    let code = getQueryVariable('code') || ''
    if (code == "") {
        // 第一步: 获取code
        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + store.state.appid + "&redirect_uri=" + encodeURIComponent(window.location.href) + "&response_type=code&scope=snsapi_base#wechat_redirect"
    } else {
        http.ajax('/ffkj-main/meetingProductVote/auth', {
            code: code,
            appId: store.state.appid
        }, 'get', {}).then(res => {
            if (res.code == 0) {
                window.localStorage.openId = res.data;
                getHTML();
            } else {
                getHTML();
                if (!(res.code == 20000)) {
                    Notify({ type: "danger", message: res.message });
                }
            }
        })
    }
}

// getHTML();

function getHTML() {
    new Vue({
        store,
        render: h => h(App)
    }).$mount('#app')
}