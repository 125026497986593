import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
let config;
if (window.location.host.indexOf('lianhuiyouxuan') != -1) {

    config = {
        httpUrl: 'https://api.lianhuiyouxuan.com',
        appid: 'wx6cdc4293332e9bad',
        imgUpdateUrl: 'https://admin.lianhuiyouxuan.com',
    }
} else if (window.location.host.indexOf('10.13.1.204') != -1) {
    config = {
        httpUrl: 'http://10.13.1.204:8764',
        appid: 'wx6cdc4293332e9bad',
        imgUpdateUrl: 'http://10.13.1.204:8099',
    }
} else {
    config = {
        httpUrl: 'https://api.lianlianyouxuan.net',
        appid: 'wx6cdc4293332e9bad',
        imgUpdateUrl: 'https://admin.lianlianyouxuan.net',
    }
}


const state = {
    appid: config.appid,
    httpUrl: config.httpUrl,
    imgUpdateUrl: config.imgUpdateUrl,
}

const mutations = {

}

export default new Vuex.Store({
    state,
    mutations
})